import React from 'react';
import { Layout, Seo } from 'components/common';
import { Why } from 'components/landing';

const WhyPage = () => (
	<Layout>
		<Seo />
		<Why />
	</Layout>
);

export default WhyPage;
